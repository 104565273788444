<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">

    <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />

    <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="PropertyCatgeorys">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <vs-button v-if="checkPermission(اضافه) || CheckUser()" type="border" icon-pack="feather" icon="icon-plus" @click="addNewData">{{$t("AddNew")}}</vs-button>


        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ PropertyCatgeorys.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : PropertyCatgeorys.length }} of {{ PropertyCatgeorys.length }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>

          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage=4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>

        </vs-dropdown>

      </div>

      <template slot="thead">
        <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{$t("Name")}}</vs-th>
        <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="NameEN">{{$t("NameEn")}}</vs-th>
        <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="image">{{$t("image")}}</vs-th>
        <!-- <vs-th sort-key="Name">{{'Format Phone'}}</vs-th> -->
        <vs-th style="font-size: 16px;font-family:almarai ; color: crimson;">{{$t('Action')}}</vs-th>
      </template>

        <template slot-scope="{data}">
          <tbody>

            <tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" @click="editData(tr)">

              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.NameAr }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.NameEn }}</p>
              </vs-td>
              <!-- <vs-td>
                <p class="product-name font-medium truncate">{{ tr.ImageUrl }}</p>
              </vs-td> -->
         
              <vs-td>
        <img v-if="tr.ImageUrl" :src="`${baseURL}${tr.ImageUrl}`"  style="width: 30px; height: 30px; object-fit: cover;" />
        <p v-else>No Image</p>
      </vs-td>
              <vs-td class="whitespace-no-wrap">
                <feather-icon v-if="checkPermission(تعديل) || CheckUser()" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editData(tr)" />
                <feather-icon v-if="checkPermission(حذف) || CheckUser()" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="openConfirm(tr)" />
              </vs-td>

            </tr>
          </tbody>
        </template>
    </vs-table>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";
import DataViewSidebar from "./DataViewSidebar.vue";
import moduleAdsCatgeory from "@/store/AdsCatgeory/moduleAdsCatgeory.js";
// import axios from "@/axios.js";

export default {
  components: {
    DataViewSidebar
  },
  data() {
    return {
      baseURL: domain,
      itemsPerPage: 10,
      isMounted: false,
      
      addNewDataSidebar: false,
      sidebarData: {},
      rowDataForDelete: {},
      activeConfirm: false
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    PropertyCatgeorys() {
     debugger;
       return this.$store.state.AdsCatgeoryList.AdsCatgeorys;
   },
  },
  methods: {
    addNewData() {
      debugger;
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    CheckUser() {
      debugger;
          if (typeof localStorage !== 'undefined') {
            const userInfo = localStorage.getItem("userInfo");
            if (userInfo) {
              return JSON.parse(userInfo).UserType === "SuperAdmin";
            }
          }
          return false; // Default return if localStorage or userInfo is not available
        },
      checkPermission(OPName) {
    const screenRolesPermissions = JSON.parse(localStorage.getItem('ScreenRolesPermissions')) || [];
    
    // Ensure Page and Page.Name are not null or undefined
    return screenRolesPermissions.some(permission => 
      permission.Operation && permission.Operation.Name === OPName
    );
  },
    deleteData(data) {
      debugger;
      this.$store.dispatch("AdsCatgeoryList/removeItem", data).then((responseData) => {
        this.$store.dispatch("AdsCatgeoryList/fetchDataListItems");
        console.log("responsedttttd:",responseData);
        this.$vs.notify({
        
                time: 8000,
                color: responseData.StatusCode === 1 ? "success" : "danger",
                title: "Delete Category",
                text: responseData.Message, // الرسالة الراجعة من الـ API
            });
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("AdsCatgeoryList/fetchDataListItems");
          this.$vs.notify({
                time: 8000,
                color: "danger",
                title: "Delete Category",
                text: err.response && err.responseData&& err.responseData.Message 
                    ? err.responseData.Message 
                    : "Failed to delete the category",
            });
        });
    },
    editData(data) {
      debugger;
      this.ParentId = data.ParentId;
      this.sidebarData = data;
      console.log("PARidedit",this.ParentId );
      this.toggleDataSidebar(true);
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    openConfirm(data) {
      debugger;
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title:"Confirm",
        text: "Are you sure you want to delete this record ?",
        accept: this.acceptAlert
      });
    },
    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      // this.$vs.notify({ time:8000,
      //   color: "success",
      //   title: "Deleted record",
      //   text: "Successfully Deleted",
      // });
     
    },
   
  },
  created() {
    debugger;
    if (!moduleAdsCatgeory.isRegistered) {
      this.$store.registerModule("AdsCatgeoryList", moduleAdsCatgeory);
      moduleAdsCatgeory.isRegistered = true;
    }
    debugger;
    this.$store.dispatch("AdsCatgeoryList/fetchDataListItems");
    

  },
  mounted() {
    this.isMounted = true;
    
  }
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
