<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: {{$t('AddNew')}} Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">

    <div class="mt-6 flex items-center justify-between px-6">
        <h4 style="color: white;">{{ Object.entries(this.data).length === 0 ?  $t("AddCat") : $t("upCat") }}</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings">

      <div class="p-6">
        <!-- NAME -->
        <label style="font-weight: bold;color: white;">{{ $t("CategoryAR") }}</label>
        <vs-input  v-model="NameAr" class="mt-5 w-full" name="NameAr"/>
        <span style="color: white;" class="text-danger text-sm" v-show="errors.has('nameAr')">{{ errors.first('nameAr') }}</span>
      </div>
     
      <div class="p-6">
        <!-- NAME -->
        <label style="font-weight: bold;color: white;">{{ $t("CategoryEN") }}</label>
        <vs-input  v-model="NameEn" class="mt-5 w-full" name="NameEN"/>
        <span class="text-danger text-sm" v-show="errors.has('NameEN')">{{ errors.first('NameEN') }}</span>
      </div>
      <div class="p-6">
        <!-- اختيار الفئة -->
        <label style="font-weight: bold;color: white;">{{ $t("MainCat") }}</label>
          <v-select
          label="NameAr" 
            class="mt-5 w-full sclass"
            name="NameAr" 
            v-model="ParentId"
            :reduce="Category => Category.ID"
            :options="categories"
           
          />
        <!-- <label style="font-weight: bold;color: white;">الفئة الرئيسية</label>
        <vs-select v-model="ParentCategory" class="mt-5 w-full" style="color: black;"  @click.stop>
          <vs-select-item v-for="category in categories" 
                          :key="category.ID" 
                          :value="category.ID" 
                          :text="category.NameAr"/>
        </vs-select> -->
      </div>
      <div class="p-6">
          <vs-input
            type="file"
            @change="AttachmentUploaded"
            accept="AllFiles/*"
           
            name="ImageUrl"
          />
      </div>
      <div class="flex flex-wrap items-center p-6">
        <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid" style="background-color: aliceblue; color: black; font-weight: bold;" color="info">{{$t("Save")}}</vs-button>
        <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{$t("Cancel")}}</vs-button>
      </div>


    </VuePerfectScrollbar>


  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moduleAdsCatgeory from "@/store/AdsCatgeory/moduleAdsCatgeory.js";
export default {
  components: {
    VuePerfectScrollbar
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    },

  },
  watch: {
    
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { ID, NameAr,NameEn,ImageUrl,ParentId} = JSON.parse(
          JSON.stringify(this.data)
        );
        this.ID = ID;
        this.NameAr = NameAr;
        this.NameEn= NameEn;
        this.ImageUrl=ImageUrl;
        this.ParentId =ParentId;
      }
      console.log("PARid",this.ParentId );

    }
  },
  data() {
    return {
      //categories: [], // تخزين قائمة الفئات
      ParentId: 0, // تخزين الفئة المختارة
      ID: 0,
      NameAr: "",
      NameEn: "",
      ImageUrl:"",
      //Code: "",
      //FormatPhone:"",
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  computed: {
    categories(){
      return this.$store.state.AdsCatgeoryList.AdsCatgeorys;
    },
   
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val && this.isSidebarActive) {
        console.log("Sidebar closed manually");
        this.$emit("closeSidebar");
      }
      }
    },
    isFormValid() {
      return !this.errors.any() && this.NameAr &&this.NameEn &&this.ImageUrl;
    }
  },
  methods: {
   
    initValues() {
      if (this.data.ID) return;
      this.ID = 0;
      this.NameAr = "";
      this.NameEn = "";
      this.ImageUrl="";
      this.ParentId=0;
      // this.Code = "";
      //this.FormatPhone ="";
    },
    AttachmentUploaded(input) {
      debugger;
      this.$vs.loading();
      debugger;
      if (input.target.files && input.target.files[0]) {
        const file = input.target.files[0];
        this.$store
          .dispatch("AdsCatgeoryList/UploadNewImage", file)
          .then((res) => {
            if (res.status == 200) {
              debugger;
              this.ImageUrl = res.data;
              this.$vs.loading.close();
              //this.successUpload();
            }
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
            window.showError();
          });
      }
    },
    submitData() {
      debugger;
      this.$validator.validateAll().then(result => {
        if (result) {
          debugger;
          const obj = {
            ID: this.ID,
            NameAr: this.NameAr,
            NameEn: this.NameEn,
            ImageUrl: this.ImageUrl,
            ParentId: this.ParentId
          };

          if (this.ID !== null && this.ID > 0) {
            debugger
            this.$store.dispatch("AdsCatgeoryList/updateItem", obj).then(() => {
              this.$store.dispatch("AdsCatgeoryList/fetchDataListItems");
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            delete obj.ID;
            obj.popularity = 0;
            this.$store
              .dispatch("AdsCatgeoryList/addItem", obj)
              .then(() => {
                this.$store.dispatch("AdsCatgeoryList/fetchDataListItems");
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          }

          this.$emit("closeSidebar");
          this.initValues();
        }
      });
    },
  },  
  created() {
    if (!moduleAdsCatgeory.isRegistered) {
      this.$store.registerModule("AdsCatgeoryList", moduleAdsCatgeory);
      moduleAdsCatgeory.isRegistered = true;
    }
    this.$store.dispatch("AdsCatgeoryList/fetchDataListItems");
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
.sclass{
  background-color: white !important;
}
</style>